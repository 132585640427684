import { FC, memo, useCallback } from 'react'

import { AccordionNoRadix, Button, Link, Icon, Text } from '@cash-me/react-components'
import { useRouter } from 'next/router'

import { Infos, Section } from 'components/general-styles'
import { Grid, Col } from 'components/grid'
import { buildSimulatorUrl } from 'utils/buildSimulatorUrl'
import { pushDataLayer } from 'utils/push-data-layer'

import { HelpProps, AccordionProps, ItemProps, ListItemProps, ChecklistItemProps } from './help.model'
import * as S from './styles'

export const Help: FC<HelpProps> = memo(({ children, accordion }) => {
  const router = useRouter()

  const handleClick = useCallback(() => {
    pushDataLayer({
      category: 'Clique',
      action: 'Ajuda',
      label: 'Botão Veja mais Dúvidas'
    })

    router.push('/ajuda')
  }, [router])

  const handleAccordionClick = (title: string) => {
    pushDataLayer({
      category: 'Clique',
      action: 'Ajuda',
      label: `Accordion Pergunta: ${title}`
    })
  }

  const handleLinkItemClick = useCallback(
    content => {
      const dataLayer = content.dataLayer
      const href = content.href

      pushDataLayer(dataLayer)

      if (href === 'simulador-home-equity')
        location.href = buildSimulatorUrl(router.pathname, { medium: 'Ajuda - ' + content.label })
      else router.push(href)
    },
    [router]
  )

  const renderItem = {
    text: (id, content) => (
      <S.Text key={id} size="body2" color="var(--colors-neutral-text-weak)">
        {content as string}
      </S.Text>
    ),
    list: (id, content) => (
      <S.List key={id}>
        {(content as ListItemProps[]).map(({ id: itemId, text }) => (
          <S.ListItem key={itemId}>{text}</S.ListItem>
        ))}
      </S.List>
    ),
    link: (id, content) => (
      <Link key={id} onClick={() => handleLinkItemClick(content)} size="default">
        <Text size="body2" weight="medium" color="var(--colors-blue-text-default)">
          {content.label}
        </Text>
        <Icon name={content.icon} size={12} />
      </Link>
    ),
    checklist: (id, content) => (
      <ul key={id}>
        {(content as ChecklistItemProps[]).map(({ id: itemId, text }) => (
          <S.ChecklistItem key={itemId}>
            <Icon name="check" color="var(--colors-cyan-icon-default)" className="checklist-icon" />
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              {text}
            </Text>
          </S.ChecklistItem>
        ))}
      </ul>
    ),
    node: (id, content) => <S.NodeContent key={id}>{content}</S.NodeContent>
  }

  return (
    <Section>
      <Grid col={{ phone: 1, tablet: 2 }}>
        <Col col={{ desktop: 5 }} start={{ desktop: 2 }}>
          <Infos>{children}</Infos>
        </Col>
        <Col col={{ desktop: 5 }} start={{ tablet: 2, desktop: 7 }}>
          <S.AccordionWrapper>
            {accordion.map(({ id, title, item }: AccordionProps) => (
              <div key={id} onClick={() => handleAccordionClick(title)}>
                <AccordionNoRadix
                  key={id}
                  id={id}
                  title={
                    <Text as="h3" size="heading4" weight="medium" color="var(--colors-neutral-text-default)">
                      {title}
                    </Text>
                  }
                >
                  {item.map(({ id, type, content }: ItemProps) => renderItem[type](id, content))}
                </AccordionNoRadix>
              </div>
            ))}
          </S.AccordionWrapper>
          <Button color="primary" size="md" iconName="arrowRight" onClick={handleClick}>
            Veja mais dúvidas
          </Button>
        </Col>
      </Grid>
    </Section>
  )
})

Help.displayName = 'Help'
