import { ItemType } from 'modules/help'

export const accordion = [
  {
    id: 'faq-0',
    title: 'Quem é a CashMe?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: 'A CashMe é uma fintech, ou seja, uma empresa de tecnologia do ramo financeiro.'
      },
      {
        id: '2',
        type: 'text' as ItemType,
        content:
          'Nós nascemos com a missão de combater a burocratização do processo de aquisição de crédito com a ajuda da tecnologia.'
      }
    ]
  },
  {
    id: 'faq-1',
    title: 'A CashMe é confiável?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'A CashMe oferece uma das linhas de crédito mais seguras do mercado: o Empréstimo com Garantia de Imóvel, também conhecido como Home Equity.'
      },
      {
        id: '2',
        type: 'text' as ItemType,
        content: 'Aproveite para conhecer nossa modalidade de crédito e faça uma simulação. '
      },
      {
        id: '3',
        type: 'link' as ItemType,
        content: {
          label: 'Simule agora',
          dataLayer: {
            category: 'Clique',
            action: 'Botões',
            label: 'Accordion Pergunta: A CashMe é confiável - Simule agora'
          },
          href: 'simulador-home-equity',
          icon: 'arrowRight'
        }
      }
    ]
  },
  {
    id: 'faq-2',
    title: 'Há quanto tempo a CashMe está no mercado?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'A CashMe foi criada em 2018 e, desde então, trabalhamos com muito empenho para oferecer crédito justo e confiável para as pessoas.'
      }
    ]
  },
  {
    id: 'faq-3',
    title: 'A CashMe atende o país todo?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'Os serviços e produtos que a gente oferece aqui na CashMe são digitais, por isso, nós conseguimos atender à grande parte do Brasil.'
      }
    ]
  },
  {
    id: 'faq-4',
    title: 'Qual é a vantagem de adquirir crédito com a CashMe?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'As pessoas que adquirem crédito aqui na CashMe contam com taxas mais baixas e prazos de pagamento flexíveis. Além de tudo, oferecemos algo essencial: atendimento personalizado.'
      },
      {
        id: '2',
        type: 'text' as ItemType,
        content:
          'Para nós, cada história é única. Por isso, queremos ajudar quem confia na gente a realizar seus sonhos e objetivos!'
      }
    ]
  }
]
