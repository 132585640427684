import { Text as TextDS } from '@cash-me/react-components'
import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, media, space, borderWidths, fontSizes, lineHeights } = theme

export const AccordionWrapper = styled.div`
  margin-bottom: ${space['spacing-xl']};

  @media (${media.desktop}) {
    margin-bottom: ${space['spacing-xxl']};
  }

  .ds-accordion-item {
    margin-bottom: ${space['spacing-xxs']};
    padding-bottom: ${space['spacing-xxs']};
    border-bottom: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};

    a {
      cursor: pointer;
    }

    div > a {
      margin-bottom: ${space['spacing-sm']};
    }
  }
`

export const NodeContent = styled.div`
  > * {
    padding-bottom: ${space['spacing-sm']};
  }

  > *:last-child {
    padding-bottom: ${space['spacing-md']};
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};
`

export const ListItem = styled.li`
  display: flex;
  margin-bottom: ${space['spacing-xxxs']};
  font-size: ${fontSizes['font-size-body-2']};
  color: ${colors['neutral-text-weak']};
  line-height: ${lineHeights['font-line-height-lg']};

  &::before {
    content: '●';
    display: inline-block;
    vertical-align: text-bottom;
    padding-right: ${space['spacing-xxs']};
    font-size: ${fontSizes['font-size-caption']};
    color: ${colors['neutral-text-weak']};
  }
`

export const ChecklistItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${space['spacing-md']};
  color: ${colors['neutral-text-weak']};
  line-height: ${lineHeights['font-line-height-lg']};

  span.checklist-icon {
    position: relative;
    top: -${space['spacing-nano']};
    margin-right: ${space['spacing-xxxs']};
  }
`

export const Text = styled(TextDS)`
  margin-bottom: ${space['spacing-sm']};
`
